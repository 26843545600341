import React, { Component } from 'react';
import {
  withStyles,
  Box,
  Grid,
  WithStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import ButtonGenerator from './ButtonGenerator';
import Navbar from '../../commons/Navbar/Navbar';

class WhatsAppLinkPage extends Component<Props, cState> {
  private instance = React.createRef<HTMLDivElement>();
  state = { name: 'New Nam' };
  componentDidMount(): void {
    console.log("Mounting new instance");
    console.log(this.state.name);
    if (this.instance != null) {
      // Download script
      const s = document.createElement('script');
      s.src = 'https://d2jyl60qlhb39o.cloudfront.net/widget-plugin-dev.js';
      // s.src = 'http://192.168.0.106:8000/test_jsPlugin.js'
      s.type = 'text/javascript';
      s.async = true;
      s.id = 'df-script';
      // Download fonts
      const url =
        'https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap';
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = url;
      document.getElementsByTagName('head')[0].appendChild(link);

      const options = {
        env: '',
        linkid: '',
        phoneNumber: '',
        variant: 'WhatsAppBtnAndChatWidget',
        btnBackground: '',
        btnCTA: 'Chat with us',
        mb: '',
        ml: '',
        mr: '',
        borderRadius: '',
        prefilledMsg: '',
        position: '',
        brandName: '',
        brandSub: '',
        brandColor: '',
        brandImgUrl: '',
        widgetBtnCTA: '',
        openWidgetByDefault: 'false',
        openWidgetSessionWindow: 'ALWAYS',
        onscreenMsg: '',
        widgetOnMobile: 'true',
      };
      s.onload = function () {
        window.CreateWhatsAppButtonAndWidget(options);
      };
      this.instance?.current?.appendChild(s);
    }
  }
  componentWillUnmount() {
    console.log("Unmounting WhatsAppBtnPage");
    const dfCont = document.getElementById("df-btn-cont");
    if (dfCont && dfCont !== null) {
      dfCont.remove();
    }
  }
  render(): React.ReactElement {
    const { classes } = this.props;
    return (
      <>
      <Navbar />
      <div className={classes.root} ref={this.instance}>
        <Grid container justify="center">
          <Grid item xs={12} md={10} lg={7}>
            {/* Put all content in here */}
            <ButtonGenerator />
          </Grid>
        </Grid>
      </div>
      </>
    );
  }
}

interface Props extends WithStyles<typeof styles> {
  // name: string,
  // number: string
}

interface cState {
  name: string;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100% !important',
      backgroundColor: 'rgb(254, 254, 254)',
      boxSizing: 'border-box',
      transition: '1s',
      // paddingBottom: "50px",
    },
  });

export default withStyles(styles)(WhatsAppLinkPage);
